import { combineReducers } from 'redux';
import { createHashHistory } from 'history';
import freshServiceReducer from './freshService/reducer';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/reducer';
import generalReducer from './general/reducer';
import rightReducer from './rights/reducer';

export const hashedHistory = createHashHistory();

export const createRootReducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    freshService: freshServiceReducer,
    right: rightReducer,
});

const store = configureStore({
    reducer: createRootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
