import { useQuery, useMutation } from '@tanstack/react-query';
import { GET_USERS_KEY,
    UPSERT_USERS_KEY,
    DELETE_USER_KEY,
    LOGOUT_USERS_KEY,
    LOGOUT_ALL_USERS_KEY,
    LINK_USER_GOOGLE_ACCOUNT,
    GET_USER_KEY,
    RESET_EMPLOYEE_CREDENTIALS,
    UPDATE_EMPLOYEE_CREDENTIALS,
    UNLINK_USER_GOOGLE_ACCOUNT
} from '../queryConstants';
import { queryErrorHandler,
    queryDeleteSuccessHandler,
    queryNoReturnContentSuccessHandler,
    queryUpdateSessionSuccessHandler,
    queryUserSuccessHandler
} from '../queryStateHandlers';
import { IUserUpsert } from '../../../@types/model/auth/user/userUpsert';
import UserHttpService from '../../../service/http/right/userHttpService';
import { IUser } from '../../../@types/model/auth/user/user';
import AuthHttpService from '../../../service/http/auth/authHttpService';

export const useGetUser = (userId : number, enabled ?: boolean) => {
    const query = useQuery<IUser, Error>({
        queryKey: [GET_USER_KEY, userId],
        queryFn: () => UserHttpService.get(userId),
        onError: queryErrorHandler('Error loading User.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useGetUsers = (enabled ?: boolean) => {
    const query = useQuery<Array<IUser>, Error>({
        queryKey: [GET_USERS_KEY],
        queryFn: ({ signal }) => UserHttpService.getList(signal),
        onError: queryErrorHandler('Error loading Users.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandleUsersUpsert = () => {
    const result = useMutation<IUser, Error, IUserUpsert>({
        mutationKey: [UPSERT_USERS_KEY],
        mutationFn: (upsert : IUserUpsert) => UserHttpService.upsert(upsert),
        onError: queryErrorHandler('Error creating/updating User.'),
        onSuccess: queryUserSuccessHandler([GET_USERS_KEY], 'User created/updated successfuly')
    });

    return result;
};

export const useHandleLinkGoogleAccount = () => {
    const result = useMutation<IUser, Error, string>({
        mutationKey: [LINK_USER_GOOGLE_ACCOUNT],
        mutationFn: (code : string) => UserHttpService.linkGoogleAccount(code),
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account linked successfully.')
    });

    return result;
};

export const useHandleUnlinkGoogleAccount = () => {
    const result = useMutation<IUser, Error, string>({
        mutationKey: [UNLINK_USER_GOOGLE_ACCOUNT],
        mutationFn: (code : string) => UserHttpService.unlinkGoogleAccount(code),
        onError: queryErrorHandler('Error encountered while trying to unlink Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account unlinked successfully.')
    });

    return result;
};

export const useHandleUpdateEmployeeCredentials = () => {
    const result = useMutation<void, Error>({
        mutationKey: [UPDATE_EMPLOYEE_CREDENTIALS],
        mutationFn: () => UserHttpService.updateEmployeeCredentials(),
        onError: queryErrorHandler('Error encountered while trying to update employee credentials.'),
        onSuccess: queryNoReturnContentSuccessHandler('Employee credentials updated successfully.')
    });

    return result;
};

export const useHandleResetEmployeeCredentials = () => {
    const result = useMutation<void, Error>({
        mutationKey: [RESET_EMPLOYEE_CREDENTIALS],
        mutationFn: () => UserHttpService.resetEmployeeCredentials(),
        onError: queryErrorHandler('Error encountered while trying to reset employee credentials.'),
        onSuccess: queryNoReturnContentSuccessHandler('Employee credentials reset successfully.')
    });

    return result;
};

export const useHandleLogoutUsers = () => {
    const result = useMutation<void, Error, Array<number>>({
        mutationKey: [LOGOUT_USERS_KEY],
        mutationFn: (userIds : Array<number>) => AuthHttpService.logoutUsers(userIds),
        onError: queryErrorHandler('Error creating/updating User.'),
        onSuccess: queryNoReturnContentSuccessHandler('User(s) logged out successfully.')
    });

    return result;
};

export const useHandleLogoutAllUsers = () => {
    const result = useMutation<void, Error>({
        mutationKey: [LOGOUT_ALL_USERS_KEY],
        mutationFn: () => UserHttpService.logoutAllUsers(),
        onError: queryErrorHandler('Error logging out all users.'),
        onSuccess: queryNoReturnContentSuccessHandler('All users logged out successfully.')
    });

    return result;
};

export const useHandleDeleteUser = (userId ?: number) => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_USER_KEY, userId],
        mutationFn: (userId : number) => UserHttpService.userDelete(userId),
        onError: queryErrorHandler('Error deactivating User.'),
        onSuccess: queryDeleteSuccessHandler([GET_USERS_KEY], 'User successfully deactivated.'),
    });

    return result;
};

