import { MutationKey, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import GeneralThunks from '../../store/general/thunk';
import { ArrayHelper } from '@zz2/zz2-ui';
import AuthActions from '../../store/auth/actions';
import { setLocalStorageSession } from '../../service/localStorage/localStorageService';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';
import { IUser } from '../../@types/model/auth/user/user';

export const queryErrorHandler = (errorMessage : string) : (error : Error) => void => {
    const dispatch = useAppDispatch();

    const handleError = (error : Error) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: errorMessage,
            ex: error,
        }));
    };
    
    return handleError;
};

export const querySuccessHandler = <T extends { id : number }>(getKey : MutationKey, successMessage : string, getKeyFilterId ?: string) : (result : T | Array<T>) => void=> {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (result : T | Array<T>) : void => {
        const isArrayType = Array.isArray(result);

        let queryKey = getKey;

        if (getKeyFilterId) {
            const dataModel = isArrayType ? result[0] : result;

            if (getKeyFilterId in dataModel) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                queryKey = [getKey, (dataModel as any)[getKeyFilterId]];
            }
        }
        
        queryClient.setQueryData(queryKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [result];

            if (isArrayType) {
                return ArrayHelper.upsertElements(cachedData, [...result], (a : T, b : T) => a.id == b.id);
            } else {
                return ArrayHelper.upsertElement(cachedData, result, (x => x.id === result.id));
            }
        });

        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryDeleteSuccessHandler = <T extends { id : number; isActive : boolean }>(getKey : MutationKey, successMessage : string) : (data : void, deletedId : number) => void => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (data : void, deletedId : number) : void => {
        queryClient.setQueryData(getKey, (cachedData ?: Array<T>) => {
            if (!cachedData) return [cachedData];

            const deletedData = cachedData.find(x => x.id === deletedId);

            if (deletedData) {
                deletedData.isActive = false;
                return ArrayHelper.upsertElement(cachedData, deletedData, (x => x.id === deletedId));
            }
            
            return;
        });

        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryNoReturnContentSuccessHandler = (successMessage : string) : () => void => {
    const dispatch = useAppDispatch();
    
    const handleOnSuccess = () : void => {
        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryUpdateSessionSuccessHandler = (successMessage : string) : (data : IUser) => Promise<void> => {
    const dispatch = useAppDispatch();
    const session = useAppSelector<IUserToken | null>(x => x.auth.session);

    const handleOnSuccess = async (data : IUser) : Promise<void> => {
        if (!session) return;

        const updatedSession = {
            ...session,
            user: data,
        };

        dispatch(AuthActions.setSession(updatedSession));
        await setLocalStorageSession(updatedSession);
        
        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryUserSuccessHandler = (getKey : MutationKey, successMessage : string) : (result : IUser) => Promise<void> => {
    const dispatch = useAppDispatch();
    const session = useAppSelector<IUserToken | null>(x => x.auth.session);
    const queryClient = useQueryClient();

    const handleOnSuccess = async (result : IUser) : Promise<void> => {
        const queryKey = getKey;

        if (!!session && session.user.id === result.id) {
            const updatedSession : IUserToken = {
                ...session,
                user: result,
            };
    
            dispatch(AuthActions.setSession(updatedSession));
            await setLocalStorageSession(updatedSession);
        }
        
        queryClient.setQueryData(queryKey, (cachedData ?: Array<IUser>) => {
            if (!cachedData) return [result];

            return ArrayHelper.upsertElement(cachedData, result, (x => x.id === result.id));
        });

        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};