import { IBaseModel, IOptionType } from '@zz2/zz2-ui';
import lodash from 'lodash';
import { IDevice } from './masterData/device/device';
import { IEmployee } from './rights/employee/employee';

export default class OptionType {
    public static fromDataModel = function<T extends IBaseModel>(dataModel : T) : IOptionType {
        let labelNameOrDescription = '';

        if ('code' in dataModel && typeof dataModel.code === 'string') {
            labelNameOrDescription += `${dataModel.code} `;
        }

        if ('name' in dataModel && typeof dataModel.name === 'string') {
            labelNameOrDescription += `${dataModel.name} `;
        }

        if ('description' in dataModel && typeof dataModel.description === 'string') {
            labelNameOrDescription += `${dataModel.description} `;
        }

        if (labelNameOrDescription === '') {
            return {
                label: 'Label Not Found',
                value: dataModel.id,
            };
        }

        const stringPartitions = labelNameOrDescription.trim().split(' ');

        if (stringPartitions.length > 1) {
            const firstSection = lodash.first(stringPartitions) ?? '';
            const lastSection = labelNameOrDescription.substring(firstSection.length);

            return {
                label: `(${firstSection}) - ${lastSection}`,
                value: dataModel.id,
            };
        } else {
            return {
                label: `${labelNameOrDescription}`,
                value: dataModel.id,
            };
        }
    }

    public static fromDevice = (device : IDevice) : IOptionType => {
        return {
            label: device.primaryImei,
            value: device.id,
        };
    }

    public static fromEmployee = (employee : IEmployee) : IOptionType => {
        return {
            label: `(${employee.number}) -  ${employee.fullName}`,
            value: employee.id,
        };
    }
}