import { IOptionType } from '@zz2/zz2-ui';
import * as Yup from 'yup';
import { FreshServiceStatusEnum } from '../../enum/freshServiceStatusEnum';
import { FreshServicePriorityEnum } from '../../enum/freshServicePriorityEnum';
import { IFreshServiceTicketFormValues } from './freshServiceTicketFormValues';
import { IFreshServiceTicketUpsert } from './freshServiceTicketUpsert';

export default class FreshServiceTicketModelHelper {
    public static createFormValues = (
        systemName : string,
        systemVersion : string,
        location : string,
        name : string,
        email : string,
        description ?: string

    ) : IFreshServiceTicketFormValues => {
        return {
            systemName: systemName,
            systemVersion: systemVersion,
            location: location,
            name: name,
            email: email,
            description: description ?? '',
            status: FreshServiceStatusEnum.Open,
            priority: FreshServicePriorityEnum.Low
        };
    };

    public static createUpsert = (freshTicketFormValues : IFreshServiceTicketFormValues) : IFreshServiceTicketUpsert => {
        return {
            systemName: freshTicketFormValues.systemName,
            systemVersion: freshTicketFormValues.systemVersion,
            location: freshTicketFormValues.location,
            name: freshTicketFormValues.name,
            email: freshTicketFormValues.email,
            description: freshTicketFormValues.description,
            priority: freshTicketFormValues.priority,
            status: freshTicketFormValues.status
        };
    };

    public static formSchema = () : Yup.ObjectSchema<IFreshServiceTicketFormValues> => Yup.object({
        systemName: Yup.string().required(),
        systemVersion: Yup.string().required(),
        location: Yup.string().required(),
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        description: Yup.string().required(),
        status: Yup.number().positive().required(),
        priority: Yup.number().positive().required(),
        referencedUser: Yup.mixed<IOptionType>().notRequired(),
    });
}
