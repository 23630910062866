import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { Link, useLocation } from 'react-router-dom';
import { CustomTooltip, IOptionType, Loader, lowercase, NavBar, NavDrawer, SideMenuItem } from '@zz2/zz2-ui';
import { useGetRights } from '../../hooks/query/right/rightsQueries';
import GeneralActions from '../../store/general/actions';
import AuthThunks from '../../store/auth/thunk';
import FreshServiceActions from '../../store/freshService/actions';
import EnvHelper from '../../service/helper/envHelper';
import { VERSION } from '../../version';
import FreshServiceTicketCreationDialog from '../freshService/FreshServiceTicketCreationDialog';
import Routes from './Routes';
import UserSettings from './UserSettings';
import lodash from 'lodash';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { IUser } from '../../@types/model/auth/user/user';
import { IRight } from '../../@types/model/auth/right/right';
import UserHelper from '../../service/helper/userHelper';
import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';
import { getUserSelectedDivisionsLocalStorage, getUserSelectedPrintServersLocalStorage } from '../../service/localStorage/localStorageService';
import RightActions from '../../store/rights/actions';

const iconsLocation = `${ASSET_BASE}/assets/icons`;

const NavFrame = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const path = useLocation().pathname;
    const currentUser : IUser | null = useAppSelector(x => x.auth.session?.user) ?? null;
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);
    const freshTicketServiceDescription = useAppSelector(x => x.freshService.supportTicketDescription);
    const isFreshServiceTicketDialogOpen = useAppSelector(x => x.freshService.isSupportTicketDialogOpen);

    const userSelectedDivisions : Array<IOptionType> = getUserSelectedDivisionsLocalStorage();
    const userSelectedPrintServers : Array<IOptionType> = getUserSelectedPrintServersLocalStorage();

    const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isLoadingRights, data: rights, refetch: loadRights } = useGetRights(false);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        setUserSettings();
    }, []);

    useEffect(() => {
        if (isNavDrawerOpen) {
            loadRights();
        }
    }, [isNavDrawerOpen]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const setUserSettings = () : void => {
        if (!currentUser) return;
        const userDivisions = currentUser.userDivisions.filter(x => x.isActive && x.division.isActive).map(x => x.division);
        const userPrintServers = currentUser.userPrintServers.filter(x => x.isActive && x.printServer.isActive).map(x => x.printServer);

        if (userDivisions.length > 0) {
            if (userSelectedDivisions.length < 1) return;

            dispatch(RightActions.setSelectedUserDivisionIds(userSelectedDivisions.map(x => Number(x.value))));
        } else {
            dispatch(RightActions.setSelectedUserDivisionIds([]));
        }

        
        if (userPrintServers.length > 0) {
            if (userSelectedPrintServers.length < 1) return;

            dispatch(RightActions.setSelectedUserPrintServerIds(userSelectedPrintServers.map(x => Number(x.value))));
        } else {
            dispatch(RightActions.setSelectedUserPrintServerIds([]));
        }
    };

    const signOut = () : void => {
        dispatch(GeneralActions.setNavDrawer(false));
        dispatch(AuthThunks.logout());
    };

    const openUserSettings = () : void => {
        setIsUserSettingsDialogOpen(true);
    };

    const closeUserSettings = () : void => {
        setIsUserSettingsDialogOpen(false);
    };

    const openDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(true));
    };

    const closeDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(false));
    };

    const handleFreshServiceTicketDialogOpen = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    const handleFreshServiceTicketDialogClose = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(false));
    };

    const requestAccess = (right : IRight) : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
        dispatch(FreshServiceActions.setSupportTicketDescription(`Requesting permission: ${right.name} - View.`));
    };

    const isParentOfCurrentPage = (parentId : number, rights ?: Array<IRight>, pathname ?: string) : boolean => {
        const currentPageRight = rights?.find(x => x.url === pathname);
        const parentRight = rights?.find(x => x.id === parentId);
    
        if (currentPageRight?.parentId) {
            return (currentPageRight.parentId === parentId) || (!!parentRight?.parentId && isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    const renderListSection = (right : IRight, children : Array<IRight>, rights ?: Array<IRight>, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x => x.isActive && x.right.id === right.id);

        return (
            <CustomTooltip
                title={!hasViewRight ? UserHelper.getRelatedRightToolTipMessage(right.name, RightGrantsEnum.View) : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'section_custom_tooltip_' + right.id}>
                <SideMenuItem
                    className={'pt10'}
                    paddingLeft={0}
                    icon={<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`}/> }
                    title={right.name}
                    key={'section_' + right.id}
                    boldTitle={isParentOfCurrentPage(right.id, rights, pathname)}
                    disabled={!hasViewRight}>
                    {children.map(x => renderListItem(x, false, pathname))}
                </SideMenuItem>
            </CustomTooltip>
        );
    };

    const renderListItem = (right : IRight, hasIcon : boolean, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x => x.isActive && x.right.id === right.id);

        return (
            <CustomTooltip
                title={!hasViewRight ? UserHelper.getRelatedRightToolTipMessage(right.name, RightGrantsEnum.View) : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'list_item_custom_tooltip_' + right.id}>
                <Link key={'list_item_link_' + right.id} to={right.url ?? ''} onClick={closeDrawer} style={{ pointerEvents: hasViewRight ? 'auto' : 'none', textDecoration: 'unset', color: 'unset' }}>
                    <ListItemButton
                        key={right.guid}
                        disabled={!hasViewRight}>
                        {
                            hasIcon ?
                                <ListItemIcon>
                                    {<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`} />}
                                </ListItemIcon>
                                :
                                <div className={'h30 w30'} />
                        }
                        <ListItemText disableTypography className={`${pathname === right.url ? 'fw550' : ''} pl0`} inset primary={right.name} />
                    </ListItemButton>
                </Link>
            </CustomTooltip>
        );
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const divisionHeading = useMemo<string>(() => {
        if (!currentUser) return '';
        return UserHelper.getUserDivisionHeading(currentUser);
    }, [currentUser, userSelectedDivisions, isUserSettingsDialogOpen]);

    const printServerHeading = useMemo<string>(() => {
        if (!currentUser) return '';
        return UserHelper.getUserPrintServerHeading(currentUser);
    }, [currentUser, userSelectedPrintServers, isUserSettingsDialogOpen]);

    const currentUserRights = useMemo<Array<IRight>>(() => {
        return currentUser?.userRights.filter(x => x.isActive).map(x => x.right) ?? [];
    }, [currentUser?.userRights]);

    const userCurrentRight = useMemo<IRight | null>(() => {
        const urlSections = path.split('/'); 
        const firstPartition = urlSections.slice(0, -1).join('/');
        
        return currentUserRights.find(
            x => x.url === (urlSections[urlSections.length - 1].includes('Id') 
                ? firstPartition
                : path
            )
        ) ?? null;

    }, [currentUser, path]);

    const breadcrumbs = useMemo<Array<{ name : string; url : string }>>(() => {
        const breadcrumbList : Array<{ name : string; url : string }> = [];

        if (userCurrentRight) {
            let currentRight : IRight | null = userCurrentRight;

            do {
                breadcrumbList.push({ name: currentRight.name, url: currentRight.url ?? '' });
                currentRight = currentUserRights.find(x => x.id === currentRight?.breadcrumbParentId) ?? null;
            } while (currentRight);
        }
        return breadcrumbList.reverse();
    }, [currentUser, path, currentUserRights, userCurrentRight]);

    const userSettings = useMemo<React.ReactElement>(() => {
        if (!isUserSettingsDialogOpen || !currentUser) return <div/>;
        return <UserSettings 
            isOpen={isUserSettingsDialogOpen}
            currentUser={currentUser}
            onClose={closeUserSettings}
            onLogout={signOut}
        />;
    }, [isUserSettingsDialogOpen, currentUser]);

    const sections = useMemo<Array<JSX.Element>>(() => {
        if (isLoadingRights) return [<Loader key={'loader'}/>];

        if (!rights) return [];

        const result = lodash.chain(rights)
            .filter(x => !!x.isOnNavDrawer && x.isActive && !x.parentId)
            .filter(x => !!x.sectionOrder)
            .sortBy(x => x.sectionOrder)
            .map((x) => {
                return {
                    section: x,
                    children: lodash.chain(rights)
                        .filter(y => !!y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                        .filter(y => !!y.pageOrder)
                        .sortBy(y => y.pageOrder)
                        .map((child) => {
                            return child;
                        })
                        .value(),
                };
            })
            .map((x) => {
                return x.section.isPage
                    ? renderListItem(x.section, true, path)
                    : renderListSection(x.section, x.children, currentUserRights, path);
            }).value();
        
        return result;
    }, [currentUser, rights, path, isLoadingRights]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <div className={'fdc hfill'}>
            <NavBar
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                currentUser={currentUser}
                signOut={signOut}
                breadcrumbs={breadcrumbs}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                openUserSettings={openUserSettings}
                closeUserSettings={closeUserSettings}
                disabled={false}       
                userSettingsDialog={userSettings}
                headings={[divisionHeading, printServerHeading]}
                isLatestVersion={true}             
            />
            <div className={'fdr flx1 oyh'}>
                <NavDrawer
                    env={EnvHelper.getEnvName()} 
                    path={path}
                    currentUser={currentUser}
                    isOpen={isNavDrawerOpen}
                    isLoading={false}
                    sections={sections}
                    logOut={signOut}
                    closeDrawer={closeDrawer}
                    onHelpClick={handleFreshServiceTicketDialogOpen}
                />
                <Routes />            
            </div>
            <FreshServiceTicketCreationDialog 
                isOpen={isFreshServiceTicketDialogOpen}
                onDismiss={handleFreshServiceTicketDialogClose}
                description={freshTicketServiceDescription}
            />
        </div>
    );
};

export default NavFrame;