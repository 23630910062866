import { RightGrantsEnum } from './@types/enum/rightGrantsEnum';

export const QUERY_DATA_STALE_TIME = 30 * 60 * 1000;

export const SNACKBAR_WIDTH = 400;

export const RIGHT_TYPE_OPTIONS : Array<{ label : string; value : number }> = [
    { label: 'General', value: 0 },
    { label: 'Rights', value: 1 },
    { label: 'MasterData', value: 2 },
    { label: 'Transaction', value: 3 },
];

export const RIGHT_LEVELS : Record<string, number> = {
    'View': RightGrantsEnum.View,
    'Add': RightGrantsEnum.Add,
    'Edit': RightGrantsEnum.Edit,
    'Delete': RightGrantsEnum.Delete,
    'Admin': RightGrantsEnum.Admin,
};

export const RIGHT_GRANT_LEVEL_OPTIONS : Array<{ label : string; value : number }> = [
    { label: 'View', value: RightGrantsEnum.View },
    { label: 'Add', value: RightGrantsEnum.Add },
    { label: 'Edit', value: RightGrantsEnum.Edit },
    { label: 'Delete', value: RightGrantsEnum.Delete },
    { label: 'Admin', value: RightGrantsEnum.Admin },
];