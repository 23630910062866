import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryDeleteSuccessHandler, queryErrorHandler, querySuccessHandler } from '../queryStateHandlers';
import { DELETE_DIVISION_KEY, GET_DIVISIONS_KEY, UPSERT_DIVISION_KEY } from '../queryConstants';
import { IDivision } from '../../../@types/model/masterData/division/division';
import { IDivisionUpsert } from '../../../@types/model/masterData/division/divisionUpsert';
import DivisionHttpService from '../../../service/http/masterData/divisionHttpService';

export const useGetDivisions = (enabled ?: boolean) : UseQueryResult<Array<IDivision>, Error> => {
    const query = useQuery<Array<IDivision>, Error>({
        queryKey: [GET_DIVISIONS_KEY],
        queryFn: () => DivisionHttpService.getList(),
        onError: queryErrorHandler('Error loading divisions.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandleDivisionUpsert = () => {
    const result = useMutation<IDivision, Error, IDivisionUpsert>({
        mutationKey: [UPSERT_DIVISION_KEY],
        mutationFn: (upsert : IDivisionUpsert) => DivisionHttpService.upsert(upsert),
        onError: queryErrorHandler('Error creating/updating Division.'),
        onSuccess: querySuccessHandler([GET_DIVISIONS_KEY], 'Division updated successfully.'),
    });

    return result;
};

export const useHandleDivisionDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_DIVISION_KEY],
        mutationFn: (id : number) => DivisionHttpService.delete(id),
        onError: queryErrorHandler('Error deleting Division.'),
        onSuccess: queryDeleteSuccessHandler([GET_DIVISIONS_KEY], 'Division inactivated successfully.'),
    });

    return result;
};