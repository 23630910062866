import { useQuery, useMutation } from '@tanstack/react-query';
import RightHttpService from '../../../service/http/right/rightHttpService';
import { GET_RIGHTS_KEY, UPSERT_RIGHT_KEY, DELETE_RIGHT_KEY } from '../queryConstants';
import { queryErrorHandler, querySuccessHandler, queryDeleteSuccessHandler } from '../queryStateHandlers';
import { IRightUpsert } from '../../../@types/model/auth/right/rightUpsert';
import { IRight } from '../../../@types/model/auth/right/right';

export const useGetRights = (enabled ?: boolean) => {
    const query = useQuery<Array<IRight>, Error>({
        queryKey: [GET_RIGHTS_KEY],
        queryFn: ({ signal }) => RightHttpService.getList(signal),
        onError: queryErrorHandler('Error loading Rights.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandleRightUpsert = () => {
    const result = useMutation<IRight, Error, IRightUpsert>({
        mutationKey: [UPSERT_RIGHT_KEY],
        mutationFn: (upsert : IRightUpsert) => RightHttpService.upsert(upsert),
        onError: queryErrorHandler('Error creating/updating Right.'),
        onSuccess: querySuccessHandler([GET_RIGHTS_KEY], 'Right updated successfully.'),
    });

    return result;
};

export const useHandleRightDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_RIGHT_KEY],
        mutationFn: (rightId : number) => RightHttpService.delete(rightId),
        onError: queryErrorHandler('Error deleting Right.'),
        onSuccess: queryDeleteSuccessHandler([GET_RIGHTS_KEY], 'Right inactivated successfully.'),
    });

    return result;
};