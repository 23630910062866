import axios from 'axios';
import { IRightUpsert } from '../../../@types/model/auth/right/rightUpsert';
import { IRight } from '../../../@types/model/auth/right/right';

export default class RightHttpService {
    public static getList = async (signal ?: AbortSignal) : Promise<Array<IRight>> => {
        const result = await axios.get(`${API_URL}/v1/Right/GetList`, {
            signal
        });

        return result.data;
    }

    public static upsert = async (upsert : IRightUpsert) : Promise<IRight> => {
        const result = await axios.post(`${API_URL}/v1/Right/Upsert`, upsert);
        return result.data;
    }

    public static delete = async (rightId : number) : Promise<void> => {
        const result = await axios.delete(`${API_URL}/v1/Right/Delete`, {
            params: { rightId },
        });

        return result.data;
    }
}
