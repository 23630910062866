import React, { Suspense } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppSelector } from '../../@types/redux';
import { Screen, AnimatedLoader } from '@zz2/zz2-ui';
import NoRightsScreen from '../right/NoRightsScreen';
import { IUserRight } from '../../@types/model/auth/user/UserRight';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    /**
     * Check if current user is associated with a right that corresponds to the destination url.
     * @param url
     */
    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;

        const userRights : Array<IUserRight> = session.user.userRights.filter(x => x.isActive);

        if (userRights.length < 1) return false;

        
        const filteredUserRights : Array<IUserRight> = userRights.filter(x => x.right.url === url);

        if (filteredUserRights.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const render = (routeProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) {
                return;
            }
            const Component = component;
            if (!hasMenuRight(routeProps.match.url)) {
                return <NoRightsScreen />;
            }
            return <Component {...routeProps} />;
        }
        return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />;
    };
    return (<Route {...rest} render={render} />);
};

{/* Home */}
const Home = React.lazy(() => import('../home/HomeScreen'));
const ContactUsManager = React.lazy(() => import('../home/contactUs/ContactUsManager'));
const NewsManager = React.lazy(() => import('../home/news/NewsManager'));
const FrequentlyAskedQuestionManager = React.lazy(() => import('../home/frequentlyAskedQuestion/FrequentlyAskedQuestionManager'));
const FrequentlyAskedQuestionScreen = React.lazy(() => import('../home/FrequentlyAskedQuestionScreen'));

{/* Rights */}
const RightManager = React.lazy(() => import('../right/rightsManagement/RightManager'));
const UserManager = React.lazy(() => import('../right/userManagement/UserManager'));
const EmployeeManager = React.lazy(() => import('../right/employee/EmployeeManager'));

{/* Master Data */}
const MasterDataManager = React.lazy(() => import('../masterData/MasterDataManager'));
const CompanyReferenceManager = React.lazy(() => import('../masterData/companyReference/CompanyReferenceManager'));
const DivisionManager = React.lazy(() => import('../masterData/division/DivisionManager'));
const DepartmentManager = React.lazy(() => import('../masterData/department/DepartmentManager'));
const InternalSystemManager = React.lazy(() => import('../masterData/internalSystem/InternalSystemManager'));
const PrintServerManager = React.lazy(() => import('../masterData/printServer/PrintServerManager'));
const CountryManager = React.lazy(() => import('../masterData/country/CountryManager'));
const DeviceManager = React.lazy(() => import('../masterData/device/DeviceManager'));
const DeviceAttributeManager = React.lazy(() => import('../masterData/deviceAttribute/DeviceAttributeManager'));
const PayPointManager = React.lazy(() => import('../masterData/payPoint/PayPointManager'));
const PayRunManager = React.lazy(() => import('../masterData/payRun/PayRunManager'));
const PersonTypeManager = React.lazy(() => import('../masterData/personType/PersonTypeManager'));

{/* Transaction */}
const PrintRequestManager = React.lazy(() => import('../transaction/PrintRequestManager'));

{/* Development */}
const LogManager = React.lazy(() => import('../logging/LogManager'));

const Routes = () : JSX.Element => {
    return (
        <Suspense fallback={<Screen><AnimatedLoader/></Screen>}>
            <Switch>
                <Route path={'/faq'} exact component={FrequentlyAskedQuestionScreen} />
                <Route
                    path={'/'} exact
                    render={() : JSX.Element => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />

                {/* Home */}
                <PrivateRoute exact path='/home' component={Home} />

                {/* Master Data */}
                <PrivateRoute exact path={'/masterData'} component={MasterDataManager}/>
                <PrivateRoute exact path={'/masterData/companyReference'} component={CompanyReferenceManager}/>
                <PrivateRoute exact path={'/masterData/division'} component={DivisionManager}/>
                <PrivateRoute exact path={'/masterData/department'} component={DepartmentManager}/>
                <PrivateRoute exact path={'/masterData/internalSystem'} component={InternalSystemManager}/>
                <PrivateRoute exact path={'/masterData/printServer'} component={PrintServerManager}/>
                <PrivateRoute exact path={'/masterData/country'} component={CountryManager}/>
                <PrivateRoute exact path={'/masterData/device'} component={DeviceManager}/>
                <PrivateRoute exact path={'/masterData/deviceAttribute'} component={DeviceAttributeManager}/>
                <PrivateRoute exact path={'/masterData/payPoint'} component={PayPointManager}/>
                <PrivateRoute exact path={'/masterData/payRun'} component={PayRunManager}/>
                <PrivateRoute exact path={'/masterData/personType'} component={PersonTypeManager}/>

                {/* Rights */}
                <PrivateRoute exact path={'/Rights/rightManager'} component={RightManager} />
                <PrivateRoute exact path={'/Rights/userManager'} component={UserManager} />
                <PrivateRoute exact path={'/Rights/employee'} component={EmployeeManager} />

                {/* Transaction */}
                <PrivateRoute exact path={'/transaction/printRequest'} component={PrintRequestManager} />

                {/* Development */}
                <PrivateRoute exact path={'/development/tools'} component={LogManager} />
                <PrivateRoute exact path={'/development/contactUsManagement'} component={ContactUsManager} />
                <PrivateRoute exact path={'/development/newsManagement'} component={NewsManager} />
                <PrivateRoute exact path={'/development/faqManagement'} component={FrequentlyAskedQuestionManager} />
            </Switch>
        </Suspense>
    );
};

export default Routes;