import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';
import { IUser } from '../../@types/model/auth/user/user';
import { IUserRight } from '../../@types/model/auth/user/UserRight';
import { getUserSelectedDivisionsLocalStorage, getUserSelectedPrintServersLocalStorage } from '../localStorage/localStorageService';

export default class UserHelper {
    public static getUserDivisionHeading = (user : IUser) : string => {
        const userDivisionIds = user.userDivisions.filter(x => x.isActive && x.division.isActive).map(x => x.divisionId);
        if (userDivisionIds.length < 1) return 'NO DIVISION SELECTED';
        const selectedDivisions = getUserSelectedDivisionsLocalStorage();
        
        const selectedDivisionCount = selectedDivisions.length;
     
        switch (selectedDivisionCount) {
            case 0:
                return 'NO DIVISION SELECTED';
            case 1:
                return selectedDivisions[0].label;
            case userDivisionIds.length:
                return 'ALL DIVISIONS';
            default:
                return 'MULTIPLE DIVISIONS';
        }
    };

    public static getUserPrintServerHeading = (user : IUser) : string => {
        const userPrintServerIds = user.userPrintServers.filter(x => x.isActive && x.printServer.isActive).map(x => x.printServerId);
        if (userPrintServerIds.length < 1) return 'NO PRINT SERVER SELECTED';
        const selectedPrintServers = getUserSelectedPrintServersLocalStorage();
        
        const selectedPrintServerCount = selectedPrintServers.length;
     
        switch (selectedPrintServerCount) {
            case 0:
                return 'NO PRINT SERVER SELECTED';
            case 1:
                return selectedPrintServers[0].label;
            case userPrintServerIds.length:
                return 'ALL PRINT SERVERS';
            default:
                return 'MULTIPLE PRINT SERVERS';
        }
    };

    public static userSpecificRightGrantLevels = (rightCode : string, userRights ?: Array<IUserRight>) => {
        const userHasRights = userRights?.filter(x => x.isActive && x.right.code === rightCode) ?? [];

        return {
            viewRight: userHasRights.some(x => !x.right.code.includes('_EDIT')),
            addRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
            editRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
            deleteRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
            adminRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
        };

        // TODO: replace with above implementation once rights management has been standardized
        // return {
        //     viewRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.View),
        //     addRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Add),
        //     editRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Edit),
        //     deleteRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Delete),
        //     adminRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Admin)
        // };
    }

    public static userRelatedRights = (pathname : string, userRights ?: Array<IUserRight>) => {
        const userHasRights = userRights?.filter(x => x.isActive && x.right.url !== null && x.right.url.toLowerCase() === pathname.toLowerCase()) ?? [];

        return {
            viewRight: userHasRights.some(x => !x.right.code.includes('_EDIT')),
            addRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
            editRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
            deleteRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
            adminRight: userHasRights.some(x => x.right.code.includes('_EDIT')),
        };

        // TODO: replace with above implementation once rights management has been standardized
        // return {
        //     viewRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.View),
        //     addRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Add),
        //     editRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Edit),
        //     deleteRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Delete),
        //     adminRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Admin)
        // };
    }

    public static getRelatedRightToolTipMessage = (dataType : string, level : RightGrantsEnum) : string => {
        let rightLevel = '';
        switch (level) {
            case (RightGrantsEnum.Add):
                rightLevel = 'Add';
                break;

            case (RightGrantsEnum.Edit):
                rightLevel = 'Edit';
                break;

            case (RightGrantsEnum.Delete):
                rightLevel = 'Delete';
                break;

            case (RightGrantsEnum.Admin):
                rightLevel = 'Admin';
                break;

            default:
                break;
        }

        return `Right: ${dataType} with Level: ${rightLevel} is required to perform this action. \n\nClick to request permission.'`;
    }
}