import axios, { AxiosPromise } from 'axios';
import { IUserUpsert } from '../../../@types/model/auth/user/userUpsert';
import { IUser } from '../../../@types/model/auth/user/user';

export default class UserHttpService {

    public static get = async (userId : number) : Promise<IUser> => {
        const result = await axios.get(`${API_URL}/v1/User/Get`, {
            params: {
                userId
            }
        });
        return result.data;
    }

    public static getList = async (signal ?: AbortSignal) : Promise<Array<IUser>> => {
        const result = await axios.get(`${API_URL}/v1/User/GetList`, {
            signal
        });
        return result.data;
    }

    public static upsert = async (upsert : IUserUpsert) : Promise<IUser> => {
        const result = await axios.post(`${API_URL}/v1/User/Upsert`, upsert);
        return result.data;
    }

    public static linkGoogleAccount = async (code : string) : Promise<IUser> => {
        const result = await axios.post(`${API_URL}/v4/User/LinkGoogleAccountToUser`, {
            code
        });

        return result.data;
    }

    public static unlinkGoogleAccount = async (code : string) : Promise<IUser> => {
        const result = await axios.post(`${API_URL}/v4/User/UnlinkGoogleAccountToUser`, {
            code
        });

        return result.data;
    }

    public static updateEmployeeCredentials = async () : Promise<void> => {
        return await axios.post(`${API_URL}/v1/User/UpdateEmployeeCredentials`);
    }

    public static resetEmployeeCredentials = async () : Promise<void> => {
        return await axios.post(`${API_URL}/v1/User/ResetEmployeeCredentials`);
    }

    public static logout = () : AxiosPromise => {
        return axios.post(`${API_URL}/v1/User/LogoutUser`);
    }

    public static logoutAllUsers = async () : Promise<void> => {
        await axios.post(`${API_URL}/v1/User/LogoutAllUsers`);
    }

    public static requestForgottenPasswordEmail(email : string) : AxiosPromise<void> {
        return axios.post(`${API_URL}/v1/User/RequestPasswordReset`, null, {
            params: {
                email,
            }
        });
    }

    public static resetPassword(oldPassword : string, newPassword : string, recoveryCode : string) : AxiosPromise<boolean> {
        return axios.post(`${API_URL}/v1/User/ResetUserPassword`, null, {
            params: {
                oldPassword,
                newPassword,
                recoveryCode,
            }
        });
    }

    public static userDelete = (userId : number) : Promise<void> => {
        return axios.delete(`${API_URL}/v1/User/Delete`, {
            params: { userId },
        });
    }
}
