import axios from 'axios';
import { IPrintServer } from '../../../@types/model/masterData/printServer/printServer';
import { IPrintServerUpsert } from '../../../@types/model/masterData/printServer/printServerUpsert';

export default class PrintServerHttpService {
    public static getList = async () : Promise<Array<IPrintServer>> => {
        const result = await axios.get(`${API_URL}/v4/MasterData/PrintServer/GetList`);

        return result.data;
    }

    public static upsert = async (upsert : IPrintServerUpsert) : Promise<IPrintServer> => {
        return await axios.post(`${API_URL}/v4/MasterData/PrintServer/Upsert`, upsert);
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v4/MasterData/PrintServer/Delete`, {
            params: { id },
        });
    }
}