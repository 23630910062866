
import { createReducer } from '@reduxjs/toolkit';
import RightActions from './actions';

export interface IRightState {
    selectedUserDivisionIds : Array<number>;
    selectedUserPrintServerIds : Array<number>;
}

const initialState = {
    masterDataSyncIsLoading: false,

    selectedUserDivisionIds: [],
    selectedUserPrintServerIds: [],
};

const rightReducer = createReducer<IRightState>(initialState, (builder) => {
    builder.addCase(RightActions.setSelectedUserDivisionIds, (state, action) => {
        state.selectedUserDivisionIds = action.payload;
    });
    builder.addCase(RightActions.setSelectedUserPrintServerIds, (state, action) => {
        state.selectedUserPrintServerIds = action.payload;
    });
    builder.addCase(RightActions.reset, () => {
        return initialState;
    });
});

export default rightReducer;
