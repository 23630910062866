import axios from 'axios';
import { IDivision } from '../../../@types/model/masterData/division/division';
import { IDivisionUpsert } from '../../../@types/model/masterData/division/divisionUpsert';

export default class DivisionHttpService {
    public static getList = async () : Promise<Array<IDivision>> => {
        const result = await axios.get(`${API_URL}/v4/MasterData/Division/GetList`);

        return result.data;
    }


    public static upsert = async (upsert : IDivisionUpsert) : Promise<IDivision> => {
        return await axios.post(`${API_URL}/v4/MasterData/Division/Upsert`, upsert);
    }

    public static delete = async (id : number) : Promise<void> => {
        return await axios.delete(`${API_URL}/v4/MasterData/Division/Delete`, {
            params: { id },
        });
    }
}