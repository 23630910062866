import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfirmationDialog, GoogleSignInButton, IGoogleLoginError, IGoogleLoginSuccess, IOptionType, MuiAutocompleteSelect, PillButton, ProjectDialog } from '@zz2/zz2-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../@types/redux';
import { useHandleLinkGoogleAccount, useHandleUnlinkGoogleAccount } from '../../hooks/query/user/userQueries';
import GeneralThunks from '../../store/general/thunk';
import { Typography } from '@mui/material';
import { IUser } from '../../@types/model/auth/user/user';
import { getUserSelectedDivisionsLocalStorage, getUserSelectedPrintServersLocalStorage, setUserSelectedDivisionsLocalStorage, setUserSelectedPrintServersLocalStorage } from '../../service/localStorage/localStorageService';
import RightActions from '../../store/rights/actions';
import { useGetPrintServers } from '../../hooks/query/masterData/printServerQueries';
import { useGetDivisions } from '../../hooks/query/masterData/divisionQueries';
import OptionType from '../../@types/model/OptionType';

interface IUserSettingsProps {
    isOpen : boolean;
    onClose : () => void;
    onLogout : () => void;
    currentUser : IUser;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch();
    const selectedDivisions = getUserSelectedDivisionsLocalStorage();
    const selectedPrintServers = getUserSelectedPrintServersLocalStorage();

    const [unlinkEmailDialogOpen, setUnlinkEmailDialogOpen] = useState<boolean>(false);
    const [unlinkEmailGoogleCode, setUnlinkEmailGoogleCode] = useState<string>('');
    const [isLogOutDialogOpen, setIsLogOutDialogOpen] = useState<boolean>(false);

    const [userSettingsChanged, setUserSettingsChanged] = useState<boolean>(false);
    const [selectedDivisionOptions, setSelectedDivisionOptions] = useState<Array<IOptionType>>([]);
    const [selectedPrintServerOptions, setSelectedPrintServerOptions] = useState<Array<IOptionType>>([]);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isLoadingDivisions, data: divisions } = useGetDivisions();
    const { isLoading: isLoadingPrintServers, data: printServers } = useGetPrintServers();

    const { isLoading: isUnlinkingEmail, mutate: unlinkEmail, isSuccess: emailUnlinkSuccessful } = useHandleUnlinkGoogleAccount();
    const { isLoading: isLinkingGoogleAccount, mutate: linkGoogleAccount } = useHandleLinkGoogleAccount();

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (props.isOpen) {
            loadData();
        }
    }, [props.isOpen, divisions, printServers]);

    useEffect(() => {
        setUnlinkEmailDialogOpen(false);

    }, [emailUnlinkSuccessful]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const onGoogleLogInSuccessLink = async (response : IGoogleLoginSuccess) : Promise<void> => {
        linkGoogleAccount(response.code);
    };

    const onGoogleLogInSuccessUnlink = async (response : IGoogleLoginSuccess) : Promise<void> => {
        setUnlinkEmailGoogleCode(response.code);
        setUnlinkEmailDialogOpen(true);
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const loadData = () : void => {
        if (props.currentUser.userDivisions.filter(x => x.isActive).length > 0) {
            const selectedDivisionOptions = divisions?.filter(div => selectedDivisions.some(localSelectedDiv => div.id === localSelectedDiv.value))
                .map(OptionType.fromDataModel) ?? [];

            if (selectedDivisions.length < 1) return;

            setSelectedDivisionOptions(selectedDivisionOptions);
        } else {
            setUserSelectedDivisionsLocalStorage([]);
        }

        
        if (props.currentUser.userPrintServers.filter(x => x.isActive).length > 0) {
            const selectedPrintServerOptions = printServers?.filter(div => selectedPrintServers.some(localSelectedDiv => div.id === localSelectedDiv.value))
                .map(OptionType.fromDataModel) ?? [];

            if (selectedPrintServers.length < 1) return;

            setSelectedPrintServerOptions(selectedPrintServerOptions);
        } else {
            setUserSelectedPrintServersLocalStorage([]);
        }
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Linking Error',
            ex: response,
        }));
    };

    const onLogoutClick = () : void => setIsLogOutDialogOpen(true);

    const handleEmailUnlinking = () : void => {
        unlinkEmail(unlinkEmailGoogleCode);
    };

    const closeEmailUnlinking = () : void => {
        setUnlinkEmailGoogleCode('');
        setUnlinkEmailDialogOpen(false);
    };

    const handleLogout = () : void => {
        props.onClose();
        props.onLogout();
    };

    const confirmUserSettingsUpdate = () : void => {
        if (selectedDivisionOptions.length < 1 || selectedPrintServerOptions.length < 1) {
            dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'Select at least one division and print server.' }));
            return;
        }

        setUserSelectedDivisionsLocalStorage(selectedDivisionOptions);
        dispatch(RightActions.setSelectedUserDivisionIds(selectedDivisionOptions.map(x => Number(x.value))));
        setUserSelectedPrintServersLocalStorage(selectedPrintServerOptions);
        dispatch(RightActions.setSelectedUserPrintServerIds(selectedPrintServerOptions.map(x => Number(x.value))));

        setUserSettingsChanged(false);
        props.onClose();
    };

    const closeUserSettings = () : void => {
        props.onClose();
        setUserSettingsChanged(false);
    };

    const onDivisionChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedDivisionOptions(selectedOptions);
        setUserSettingsChanged(true);
    };

    const onPrintServerChange = (selectedOptions : Array<IOptionType>) : void => {
        setSelectedPrintServerOptions(selectedOptions);
        setUserSettingsChanged(true);
    };

    const onDivisionSelectAll = () : void => {
        if (divisionOptions.length !== selectedDivisionOptions.length) {
            onDivisionChange(divisionOptions);
        } else {
            onDivisionChange([]);
        }
        setUserSettingsChanged(true);
    };

    const onPrintServerSelectAll = () : void => {
        if (printServerOptions.length !== selectedPrintServerOptions.length) {
            onPrintServerChange(printServerOptions);
        } else {
            onPrintServerChange([]);
        }
        setUserSettingsChanged(true);
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const isLoading = useMemo(() => {
        return isLoadingDivisions
            || isLoadingPrintServers
            || isLinkingGoogleAccount;
    }, [isLoadingDivisions, isLoadingPrintServers, isLinkingGoogleAccount]);

    const divisionOptions = useMemo<Array<IOptionType>>(() => {
        const userDivisionIds = props.currentUser.userDivisions.filter(x => x.isActive && x.division.isActive).map(x => x.divisionId);
        if (!divisions || userDivisionIds.length < 1) return [];

        return divisions
            .filter(div => div.isActive && userDivisionIds.some(divId => div.id === divId))
            .map(OptionType.fromDataModel);
    }, [divisions, props.currentUser]);

    const printServerOptions = useMemo<Array<IOptionType>>(() => {
        const userPrintServerIds = props.currentUser.userPrintServers.filter(x => x.isActive && x.printServer.isActive).map(x => x.printServerId);
        if (!printServers || userPrintServerIds.length < 1) return [];

        return printServers
            .filter(x => x.isActive && userPrintServerIds.some(y => x.id === y))
            .map(OptionType.fromDataModel);
    }, [printServers, props.currentUser]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <>
            <ProjectDialog
                title={'User Settings'}
                isOpen={props.isOpen}
                maxWidth={'sm'}
                isLoadingCircular={isLoading}
                onConfirm={confirmUserSettingsUpdate}
                disableConfirm={!userSettingsChanged || selectedDivisionOptions.length == 0 || selectedPrintServerOptions.length == 0}
                onClose={closeUserSettings}
                closeToolTip={(selectedDivisionOptions.length < 1 || selectedPrintServerOptions.length < 1) ? 'Please select at least one division and department.' : ''}
                disableClose={selectedDivisionOptions.length < 1 || selectedPrintServerOptions.length < 1}>
                <div className={'fdc h500 flx1 aic m10 pt10'}>
                    <div className={'fdc flx1'}>
                        <div className={'fdr wfill jcfe mb5'}>
                            <PillButton
                                text={(divisionOptions.length > 0 && divisionOptions.length === selectedDivisionOptions.length) ? 'Unselect All' : 'Select All'}
                                className={'h20 w120'}
                                onClick={onDivisionSelectAll}
                                color={'secondary'}
                                size={'small'}
                                disabled={divisionOptions.length < 1 || isLoading} />
                        </div>
                        <MuiAutocompleteSelect
                            isMulti
                            className={'w400 h130 oya'}
                            name={'divisions'}
                            label={'Divisions'}
                            options={divisionOptions}
                            value={selectedDivisionOptions}
                            onChange={onDivisionChange}
                            disableClearable
                            tagLimit={5}
                        />
                        <div className={'fdr wfill jcfe mt30 mb5'}>
                            <PillButton
                                text={(printServerOptions.length > 0 && printServerOptions.length === selectedPrintServerOptions.length) ? 'Unselect All' : 'Select All'}
                                className={'h20 w120'}
                                onClick={onPrintServerSelectAll}
                                color={'secondary'}
                                size={'small'}
                                disabled={printServerOptions.length < 1 || isLoading} />
                        </div>
                        <MuiAutocompleteSelect
                            isMulti
                            className={'w400 h130 oya'}
                            name={'printServers'}
                            label={'Print Servers'}
                            options={printServerOptions}
                            value={selectedPrintServerOptions}
                            onChange={onPrintServerChange}
                            disableClearable
                            tagLimit={5}
                        />
                    </div>
                    <div className={'fdc aic'}>
                        {
                            props.currentUser.employee?.workEmail == null
                                ? <div className={'zi1'}> 
                                    <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                        <GoogleSignInButton          
                                            onGoogleSignInSuccess={onGoogleLogInSuccessLink}
                                            onGoogleSignInFailure={onGoogleLogInFailure}   
                                            buttonText={'Link Google Account'}                                   
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                                : 
                                <div className={'fdc aic jcc'}>
                                    <div className={'fdr aic jcc'}>
                                        <Typography className={'fdr mr10'}>Linked Email:</Typography>
                                        <Typography variant={'body1'} className={'fdr fwb cpd'}>{ props.currentUser.employee.workEmail }</Typography>
                                    </div>
                                    <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                        <GoogleSignInButton
                                            onGoogleSignInSuccess={onGoogleLogInSuccessUnlink}
                                            onGoogleSignInFailure={onGoogleLogInFailure}
                                            buttonText={'Unlink Account'}
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                        }
                    </div>
                    <div className={'h30'}/>
                    <div className={'fdr aic pb20'}>
                        <PillButton
                            className={'h35 w150'}
                            text={'LOGOUT'}
                            color={'secondary'}
                            onClick={onLogoutClick}
                        />
                    </div>
                </div>
                <ConfirmationDialog 
                    open={unlinkEmailDialogOpen}
                    title={'Unlink Email'}
                    isLoading={isUnlinkingEmail}
                    description={'Are you sure you would like to unlink the email address currently associated with your account?'}
                    onAccept={handleEmailUnlinking}
                    onClose={closeEmailUnlinking}
                    dialogType={'orange'}
                />
                <ConfirmationDialog
                    isLoading={false}
                    open={isLogOutDialogOpen}
                    title={'Log Out?'}
                    description={'Are you sure you would like to logout?'}
                    onAccept={handleLogout}
                    onClose={() : void => setIsLogOutDialogOpen(false)}
                    dialogType={'red'}
                />
            </ProjectDialog>
        </>
    );
};

export default UserSettings;