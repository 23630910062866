import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { queryDeleteSuccessHandler, queryErrorHandler, querySuccessHandler } from '../queryStateHandlers';
import { IPrintServer } from '../../../@types/model/masterData/printServer/printServer';
import { IPrintServerUpsert } from '../../../@types/model/masterData/printServer/printServerUpsert';
import { GET_PRINT_SERVERS_KEY, UPSERT_PRINT_SERVER_KEY, DELETE_PRINT_SERVER_KEY } from '../queryConstants';
import PrintServerHttpService from '../../../service/http/masterData/printServerHttpService';

export const useGetPrintServers = (enabled ?: boolean) : UseQueryResult<Array<IPrintServer>, Error> => {
    const query = useQuery<Array<IPrintServer>, Error>({
        queryKey: [GET_PRINT_SERVERS_KEY],
        queryFn: () => PrintServerHttpService.getList(),
        onError: queryErrorHandler('Error loading print servers.'),
        enabled: enabled ?? true,
    });

    return query;
};

export const useHandlePrintServerUpsert = () => {
    const result = useMutation<IPrintServer, Error, IPrintServerUpsert>({
        mutationKey: [UPSERT_PRINT_SERVER_KEY],
        mutationFn: (upsert : IPrintServerUpsert) => PrintServerHttpService.upsert(upsert),
        onError: queryErrorHandler('Error creating/updating print server.'),
        onSuccess: querySuccessHandler([GET_PRINT_SERVERS_KEY], 'Print Server updated successfully.'),
    });

    return result;
};

export const useHandlePrintServerDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_PRINT_SERVER_KEY],
        mutationFn: (id : number) => PrintServerHttpService.delete(id),
        onError: queryErrorHandler('Error deleting print server.'),
        onSuccess: queryDeleteSuccessHandler([GET_PRINT_SERVERS_KEY], 'Print Server inactivated successfully.'),
    });

    return result;
};