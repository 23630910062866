import React from 'react';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button } from '@mui/material';
import { ControlledMuiAutoCompleteSelect, ControlledTextField, IOptionType, PillButton } from '@zz2/zz2-ui';
import { useForm } from 'react-hook-form';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketFormValues';

interface IFreshServiceTicketFormProps {
    initialValues : IFreshServiceTicketFormValues;
    onSubmit : (values : IFreshServiceTicketFormValues) => void;
    onCancel : () => void;

    userOptions : Array<IOptionType>;
}

const FreshServiceTicketForm = (props : IFreshServiceTicketFormProps) : React.ReactElement => {
    const schema = FreshServiceTicketModelHelper.formSchema();

    const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<IFreshServiceTicketFormValues>({
        defaultValues: props.initialValues,
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <div className={'fdc p30 pt15 pb0'}>
                    <div className={'fdc wfill pt10 jcc aic'}>
                        <ControlledTextField
                            control={control} 
                            label={'System Name'} 
                            name={'systemName'}
                            className={'wfill'}
                            disabled={true}
                        />
                        <div className='h10' />
                        <ControlledTextField
                            control={control} 
                            label={'System Version'} 
                            name={'systemVersion'}
                            className={'wfill'}
                            disabled={true}
                        />
                        <div className='h10' />
                        <ControlledTextField
                            control={control} 
                            label={'Location'} 
                            name={'location'}
                            className={'wfill'}
                            disabled={true}
                        />
                        <div className='h10' />
                        <ControlledTextField
                            control={control} 
                            label={'Name'} 
                            name={'name'}
                            className={'wfill'}
                            disabled={true}
                        />
                        <div className='h10' />
                        <ControlledTextField
                            control={control} 
                            label={'Email'} 
                            name={'email'}
                            className={'wfill'}
                            disabled={true}
                        />
                        <div className='h10' />
                        <TextField
                            {...register('description')}
                            label={'Description'}
                            className={'wfill'}
                            multiline={true}
                            rows={4}
                            variant={'standard'}
                            error={!!errors.description}
                            helperText={errors.description ? errors.description.message : ''}
                        />
                        <div className='h10' />
                        <ControlledMuiAutoCompleteSelect 
                            name='referencedUser'
                            control={control}
                            label={'Manager'} 
                            className={'wfill'}
                            defaultValues={props.initialValues.referencedUser ?? null}
                            options={props.userOptions}
                        />
                    </div>
                    <div className='h20' />
                    <div className={'fdr flx1 aic jcfe mb20'}>
                        <Button
                            className={'cpd mr20'}
                            onClick={props.onCancel}
                            type={'reset'}>
                            CANCEL
                        </Button>
                        <PillButton
                            className={'pl30 pr30'}
                            text={'SAVE'}
                            size={'small'}
                            type={'submit'}
                            color={'secondary'}
                            disabled={!isValid}
                        />
                    </div>
                </div>
            </form>
            <DevTool control={control} />
        </>
    );
};

export default FreshServiceTicketForm;
