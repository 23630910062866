import axios, { AxiosPromise } from 'axios';
import { ILogInView } from '../../../@types/model/auth/logIn/LogInView';
import { IGoogleLogInView } from '../../../@types/model/auth/logIn/GoogleLogInView';
import { ISignUpView } from '../../../@types/model/auth/logIn/SignUpView';
import { IUserToken } from '../../../@types/model/auth/userToken/userToken';

export default class AuthHttpService {

    // Naming of the profile picture field differs from the API.
    // Since the navbar from the UI package expect it to be profilePictureURL,
    // we therefore map googleProfilePictureURL from the API to the corresponding profilePictureURL property on the web model.
    private static mapToWebUser = async (result : any) : AxiosPromise<IUserToken> => {
        return {
            ...result,
            data: {
                ...result.data,
                user: {
                    ...result.data.user,
                    profilePictureURL: result.data.user.googleProfilePictureURL,
                }
            }
        };
    };

    public static signUp = async (signUpView : ISignUpView) : AxiosPromise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v4/Authorisation/SignUp`, signUpView);
        return this.mapToWebUser(result);
    };

    public static logInManual = async (logInView : ILogInView) : AxiosPromise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v4/Authorisation/LogIn`, logInView);
        return this.mapToWebUser(result);
    }

    public static googleLogIn = async (googleLogInView : IGoogleLogInView) : AxiosPromise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v4/Authorisation/LogInGoogle`, googleLogInView);
        return this.mapToWebUser(result);
    }

    public static getSession = async () : AxiosPromise<IUserToken> => {
        const result = await axios.get(`${API_URL}/v4/Authorisation/Session`);
        return this.mapToWebUser(result);
    }

    public static logout = () : AxiosPromise => {
        return axios.get(`${API_URL}/v4/Authorisation/Logout`);
    }

    public static logoutUsers = async (userIds : Array<number>) : Promise<void> => {
        await axios.post(`${API_URL}/v1/Authorisation/LogoutUsers`, userIds);
    }
}
